// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/oNas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/politykaCookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-wizyty-prywatne-js": () => import("./../../../src/pages/wizytyPrywatne.js" /* webpackChunkName: "component---src-pages-wizyty-prywatne-js" */),
  "component---src-pages-zabiegi-refundowane-js": () => import("./../../../src/pages/zabiegiRefundowane.js" /* webpackChunkName: "component---src-pages-zabiegi-refundowane-js" */)
}

