module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2MWK2BQPVH"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Poradnia Rehabilitacyjna Azory","short_name":"Azory","start_url":"/","background_color":"#ffffff","theme_color":"#2bc1b9","display":"minimal-ui","icon":"src/images/sygnet.png","lang":"pl","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"518c188e3c1aabd1b2e8803aba87ddcd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
